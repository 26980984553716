<template>
  <div>
    <animal-list-mobility
      v-if="animal.id != null"
      :key="animal.id"
      :selected-animal="animal"
    />
  </div>
</template>

<script>
import AnimalListMobility from "./components/AnimalMobility/AnimalListMobility.vue";
import { mapState } from "vuex";
import VueI18n from "@/libs/i18n";

export default {
  components: { AnimalListMobility },
  computed: {
    ...mapState({
      animal: (state) => state.animalsModule.selectedAnimal || {},
    }),
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "animal-mobility") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("mobilities"),
        active: true,
      });
    }

    next();
  },
};
</script>

<style></style>
