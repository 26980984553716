import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useMobilityList() {
  // Use toast
  const toast = useToast();

  const fetchMobilitys = async (ctx) => {
    console.log("fetchMobilitys", ctx);
    var mobility = await store
      .dispatch("animalsModule/fetchAnimalMobilities", ctx)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("mobilitys"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return mobility;
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchMobilitys,
  };
}
