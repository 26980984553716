<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 p-0">
      <div v-if="!loading" id="wrapper">
        <div id="chart-line">
          <apexchart
            :key="JSON.stringify(chartOptions)"
            ref="movementChart"
            height="300"
            :options="chartOptions"
            type="line"
            :series="movementsSeries"
          ></apexchart>
        </div>
        <div id="chart-line2">
          <apexchart
            :key="JSON.stringify(chartOptionsLine2)"
            ref="ruminationChart"
            type="line"
            height="300"
            :options="chartOptionsLine2"
            :series="ruminationsSeries"
          ></apexchart>
        </div>
      </div>
      <div
        v-else
        class="text-center flex justify-content-center align-items-center"
        style="height: 300px"
      >
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-spinner></b-spinner>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BSpinner } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useMobilityList from "./useMobilityList";
import isHeatImage from "@/assets/images/icons/isHeat.png";
import isRuminationHeatImage from "@/assets/images/icons/isRuminationHeat.png";
import isStrongHeatImage from "@/assets/images/icons/isStrongHeat.png";
import isKetosisImage from "@/assets/images/icons/isKetosis.png";
import isMastitisImage from "@/assets/images/icons/isMastisis.png";
import isVaccineImage from "@/assets/images/icons/isVaccine.png";
import VueI18n from "@/libs/i18n";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
import Vue from "vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { is } from "vee-validate/dist/rules";
export default {
  components: {
    BCard,
    BSpinner,
  },
  props: {
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dayjs,
      allMobilitys: [],
      movements: [],
      ruminations: [],
      loading: true,
      movementsSeries: [
        {
          name: "Adım",
          data: [],
          type: "line",
        },
        {
          name: VueI18n.t("treshold"),
          data: [],
          type: "line",
          color: "#b4b7bd",
        },
      ],
      chartOptions: {
        chart: {
          id: "adim",
          group: "movement",
          type: "line",
          height: 160,
          toolbar: {
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true,
              customIcons: [],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        colors: ["#ea5455"],
        grid: {
          show: true,
          borderColor: "#b4b7bd",
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        annotations: {
          yaxis: [],
          points: [],
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 2,
          dashArray: [0, 8],
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: [0],

          y: {
            title: {
              formatter: (val, opts) => {
                return VueI18n.t("step") + ":";
              },
            },
            formatter: (val, opts) => {
              var { dataPointIndex } = opts;
              var isHeat = this.allMobilitys[dataPointIndex].isheat;
              var isruminationheat =
                this.allMobilitys[dataPointIndex].isruminationheat;
              var isKetosis = this.allMobilitys[dataPointIndex].isketosis;
              var isMastitis = this.allMobilitys[dataPointIndex].ismastitis;
              var isVacine = this.allMobilitys[dataPointIndex].isvacine;

              var heatingDegree =
                isHeat && isruminationheat
                  ? VueI18n.t("highHeat")
                  : isHeat
                  ? VueI18n.t("heatMovement")
                  : isruminationheat
                  ? VueI18n.t("heatRumination")
                  : "";

              var string = `${val} ${heatingDegree} ${
                isKetosis ? VueI18n.t("ketosis") : ""
              } ${isMastitis ? VueI18n.t("mastitis") : ""}
              ${isVacine ? VueI18n.t("vacine") : ""}`;
              return string;
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: "#b4b7bd",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#b4b7bd",
              fontWeight: 600,
            },
          },
        },
      },

      ruminationsSeries: [
        {
          name: "Gevis",
          data: [],
        },
      ],
      chartOptionsLine2: {
        chart: {
          id: "gevis",
          group: "movement",
          type: "line",
          height: 160,
          toolbar: {
            show: false,
          },
        },
        colors: ["#28c76f"],
        grid: {
          show: true,
          borderColor: "#b4b7bd",
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        annotations: {
          yaxis: [
            {
              y: 450,
              y2: 400,
              borderColor: "#000",
              fillColor: "#7BF371",
              label: {
                text: "Ketosis",
              },
            },
            {
              y: 400,
              y2: 350,
              borderColor: "#000",
              fillColor: "#00B2FF",
              label: {
                text: "Mastisis",
              },
            },
            {
              y: 350,
              y2: 200,
              borderColor: "#000",
              fillColor: "#FF7F4F",
              label: {
                text: VueI18n.t("Heat"),
              },
            },
          ],
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "butt",
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        xaxis: {
          labels: {
            style: {
              colors: "#b4b7bd",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          max: 520,
          labels: {
            style: {
              colors: "#b4b7bd",
              fontWeight: 600,
            },
            axisBorder: {
              show: true,
            },
          },
        },

        tooltip: {
          enabled: true,
          y: {
            title: {
              formatter: (val, opts) => {
                return VueI18n.t("rumination") + ":";
              },
            },
            formatter: (val, opts) => {
              var { dataPointIndex } = opts;
              var isruminationheat =
                this.allMobilitys[dataPointIndex].isruminationheat;
              var isKetosis = this.allMobilitys[dataPointIndex].isketosis;
              var isMastitis = this.allMobilitys[dataPointIndex].ismastitis;
              var isVacine = this.allMobilitys[dataPointIndex].isvacine;
              var isHeat = this.allMobilitys[dataPointIndex].isheat;
              var heatingDegree =
                isHeat && isruminationheat
                  ? VueI18n.t("highHeat")
                  : isHeat
                  ? VueI18n.t("heatMovement")
                  : isruminationheat
                  ? VueI18n.t("heatRumination")
                  : "";
              var string = `${val} ${heatingDegree} ${
                isKetosis ? VueI18n.t("ketosis") : ""
              } ${isMastitis ? VueI18n.t("mastitis") : ""} ${
                isVacine ? VueI18n.t("vacine") : ""
              }`;
              return string;
            },
          },
        },
      },
    };
  },
  computed: {
    isDark() {
      const { skin } = useAppConfig();
      return skin.value === "dark";
    },
  },
  watch: {
    isDark: function () {
      this.loading = true;
      var color = this.isDark ? "#b4b7bd" : "#6e6b7b";
      this.chartOptions.xaxis.labels.style.colors = color;
      this.chartOptions.yaxis.labels.style.colors = color;
      this.chartOptionsLine2.xaxis.labels.style.colors = color;
      this.chartOptionsLine2.yaxis.labels.style.colors = color;

      this.chartOptions.grid.borderColor = color;
      this.chartOptionsLine2.grid.borderColor = color;

      this.movementsSeries[1].color = color;
      this.loading = false;
    },
    loading: function () {
      if (!this.loading)
        setTimeout(() => {
          this.setDotAnnotations();
        }, 1000);
    },
  },

  mounted: function () {
    this.getMovements();
  },
  methods: {
    avatarText,
    getMovements: async function () {
      this.loading = true;
      const { fetchMobilitys } = useMobilityList();
      this.allMobilitys = await fetchMobilitys(this.selectedAnimal.id);

      this.allMobilitys = this.allMobilitys.map((mobility) => {
        return {
          ...mobility,
          adim: mobility.adim ?? 0,
          gevis: mobility.gevis ?? 0,
          isheat: mobility.isheat ?? false,
          isruminationheat: mobility.isruminationheat ?? false,
          isketosis: mobility.isketosis ?? false,
          ismastitis: mobility.ismastitis ?? false,
          isvacine: mobility.isvacine ?? false,
          heatdata: mobility.heatdata ?? 0,
          tarih: mobility.tarih ?? Date.now(),
        };
      });
      this.getMovementsData();
      this.getRuminationsData();
      this.setMarkers();
      this.loading = false;
    },
    setDotAnnotations: function () {
      var movementChartRef = this.$refs.movementChart;
      var ruminationChartRef = this.$refs.ruminationChart;

      var scales = movementChartRef.chart.axes.w.globals.yAxisScale[0].result;
      var maxForMovement = Math.max(...scales);

      var scales2 =
        ruminationChartRef.chart.axes.w.globals.yAxisScale[0].result;
      var maxForRumination = Math.max(...scales2);

      var discrates = this.allMobilitys
        .map((mobility, index) => {
          if (mobility.isheat && mobility.isruminationheat) {
            return {
              x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
              y: 100.55,
              image: {
                path: isStrongHeatImage,
                width: 30,
                height: 30,
              },
              isForMovement: true,
            };
          } else if (mobility.isheat) {
            return {
              x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
              y: 100.55,
              image: {
                path: isHeatImage,
                width: 30,
                height: 30,
              },
              isForMovement: true,
            };
          } else if (mobility.isruminationheat) {
            return {
              x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
              y: 100.55,
              image: {
                path: isRuminationHeatImage,
                width: 30,
                height: 30,
              },
              isForMovement: true,
            };
          } else if (mobility.isketosis) {
            return {
              x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
              y: 100.55,
              image: {
                path: isKetosisImage,
                width: 30,
                height: 30,
              },
              isForMovement: false,
            };
          } else if (mobility.ismastitis) {
            return {
              x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
              y: 100.55,
              image: {
                path: isMastitisImage,
                width: 30,
                height: 30,
              },
              isForMovement: false,
            };
          } else if (mobility.isvacine) {
            return {
              x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
              y: 100.55,
              image: {
                path: isVaccineImage,
                width: 40,
                height: 40,
              },
              isForMovement: true,
            };
          } else {
            return null;
          }
        })
        .filter((item) => item);

      discrates.forEach((item) => {
        if (item.isForMovement)
          movementChartRef.chart.addPointAnnotation({
            ...item,
            y: maxForMovement,
          });
        else
          ruminationChartRef.chart.addPointAnnotation({
            ...item,
            y: maxForRumination,
          });
      });
    },
    setMarkers: function () {
      this.loading = true;
      var discrates = this.allMobilitys
        .map((mobility, index) => {
          if (mobility.isheat && mobility.isruminationheat) {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: "#FF0000",
              strokeColor: "#fff",
              size: 5,
              shape: "square", // "circle" | "square" | "rect"
              showFor: "both",
            };
          } else if (mobility.isheat) {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: "#813EEE",
              strokeColor: "#fff",
              size: 5,
              shape: "circle", // "circle" | "square" | "rect"
              showFor: "movement",
            };
          } else if (mobility.isruminationheat) {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: "#F37171",
              strokeColor: "#fff",
              size: 5,
              shape: "square", // "circle" | "square" | "rect",
              showFor: "rumination",
            };
          } else if (mobility.isketosis) {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: "#7BF371",
              strokeColor: "#fff",
              size: 5,
              shape: "circle", // "circle" | "square" | "rect"
              showFor: "rumination",
            };
          } else if (mobility.ismastitis) {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: "#00B2FF",
              strokeColor: "#fff",
              size: 5,
              shape: "square", // "circle" | "square" | "rect",
              showFor: "rumination",
            };
          } else if (mobility.isvacine) {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: "#FF7F4F",
              strokeColor: "#fff",
              size: 5,
              shape: "rect", // "circle" | "square" | "rect"
              showFor: "both",
            };
          } else {
            return null;
          }
        })
        .filter((item) => item);

      this.chartOptionsLine2.markers = {
        discrete: discrates.filter(
          (item) => item.showFor === "rumination" || item.showFor === "both"
        ),
      };
      this.chartOptions.markers = {
        discrete: discrates.filter(
          (item) => item.showFor === "movement" || item.showFor === "both"
        ),
      };

      this.loading = false;
    },
    getMovementsData: function () {
      var movements = this.allMobilitys.map((mobility) => {
        return {
          x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
          y: mobility.adim ?? 0,
        };
      });
      this.movementsSeries[0].data = movements;

      this.movementsSeries[1].data = this.allMobilitys.map((movement) => {
        return {
          x: dayjs(movement.tarih).format("DD/MM/YYYY"),
          y: Math.floor(parseFloat(movement.heatdata)),
        };
      });
    },
    getRuminationsData: function () {
      var ruminations = this.allMobilitys.map((mobility) => {
        return {
          x: dayjs(mobility.tarih).format("DD/MM/YYYY"),
          y: mobility.gevis ?? 0,
        };
      });
      this.ruminationsSeries[0].data = ruminations;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
